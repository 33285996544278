import { throttle } from './functions/throttle';
let yourFunc = () => { console.log('throttle') };
let func = throttle(yourFunc);
window.addEventListener('resize', func);

import { burger } from './functions/burger';

import GraphModal from 'graph-modal';
const modal = new GraphModal();

import GraphTabs from 'graph-tabs';

let mytabs = document.querySelector('.tabs');
  if (mytabs) {
    const tabs = new GraphTabs('tab');
  }

import AOS from 'aos';
AOS.init({
	disable: 'phone'
});

import SmoothScroll from 'smooth-scroll';
const scroll = new SmoothScroll('a[href*="#"]');

import { validateForms } from './functions/validate-forms';
const rules = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];
const rules1 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];
const rules2 = [
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];
const rules3 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm = () => {
  console.log('Произошла отправка формы');
};

let myform = document.querySelector('.form');
  if (myform) {
  validateForms('.form', rules, afterForm);
  }

let myform1 = document.querySelector('.form-site');
  if (myform1) {
  validateForms('.form-site', rules3, afterForm);
  }

let myform2 = document.querySelector('.form-review');
  if (myform2) {
  validateForms('.form-review', rules1, afterForm);
  }

let myform3 = document.querySelector('.form-seo');
  if (myform3) {
  validateForms('.form-seo', rules2, afterForm);
  }
