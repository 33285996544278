const heroSliderSpeed = 1500;

const bodyStyles = window.getComputedStyle(document.body);
const fooBar = bodyStyles.getPropertyValue('--hero-slider-speed');

document.body.style.setProperty('--hero-slider-speed', heroSliderSpeed + 'ms');

import Swiper, { Navigation, Pagination } from 'swiper/bundle';
Swiper.use([Navigation, Pagination]);
const heroSlider = new Swiper('.hero-slider', {
  slidesPerView: 1,
  sdirection: "vertical",
  speed: heroSliderSpeed,
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: '.hero__pag',
    type: 'bullets',
    clickable: true
  },
  on: {
      init: function () {
        const paginationBullets = document?.querySelectorAll('.hero__pag .swiper-pagination-bullet');

        paginationBullets.forEach(el => {
          el.innerHTML = `<span class="hero__bar"></span>`;
        });
      },
    },
});
