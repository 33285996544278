let myshowmore = document.querySelector('.show-more');
  if (myshowmore) {
    const showMore = document.querySelector('.show-more');
    const productsLength = document.querySelectorAll('.project').length;
    let items = 3;

    showMore.addEventListener('click', () => {
      items += 3;
      const array = Array.from(document.querySelector('.projects').children);
      const visItems = array.slice(0, items);

      visItems.forEach(el => el.classList.add('is-visible'));

      if (visItems.length === productsLength) {
        showMore.style.display = 'none';
      }
  });
}
